let IDX = 36;
let HEX = "";
while (IDX--) {
  HEX += IDX.toString(36);
}
function uid(len = 11) {
  let str = "";
  let num = len;
  while (num--) {
    str += HEX[Math.random() * 36 | 0];
  }
  return str;
}

const escapeRegexp = (term) => term.replace(/[|\\{}()[\]^$+*?.-]/g, (char) => `\\${char}`);
const termsToRegExpString = (terms) => terms.replace(/\s{2,}/g, " ").split(" ").join("|");
const regexpQuery = ({
  terms,
  matchExactly = false
}) => {
  if (typeof terms !== "string") {
    throw new TypeError("Expected a string");
  }
  const escapedTerms = escapeRegexp(terms.trim());
  return `(${matchExactly ? escapedTerms : termsToRegExpString(escapedTerms)})`;
};
const buildRegexp = ({
  terms,
  matchExactly = false
}) => {
  try {
    const fromString = /^([/~@;%#'])(.*?)\1([gimsuy]*)$/.exec(terms);
    if (fromString) {
      return new RegExp(fromString[2], fromString[3]);
    }
    return new RegExp(regexpQuery({ terms, matchExactly }), "ig");
  } catch (e) {
    throw new TypeError("Expected terms to be either a string or a RegExp!");
  }
};

const hasProp = (prop) => (obj) => obj !== null && typeof obj === "object" && prop in obj;
const hasMatch = hasProp("match");
const chunkExists = (chunk) => typeof chunk !== "undefined";
function clip({
  curr,
  next,
  prev,
  clipBy = 3
}) {
  const words = curr.text.split(" ");
  const len = words.length;
  if (curr.match || clipBy >= len) {
    return curr.text;
  }
  const ellipsis = "...";
  if (chunkExists(next) && chunkExists(prev) && hasMatch(prev) && hasMatch(next)) {
    if (len > clipBy * 2) {
      return [
        ...words.slice(0, clipBy),
        ellipsis,
        ...words.slice(-clipBy)
      ].join(" ");
    }
    return curr.text;
  }
  if (chunkExists(next) && hasMatch(next)) {
    return [ellipsis, ...words.slice(-clipBy)].join(" ");
  }
  if (chunkExists(prev) && hasMatch(prev)) {
    return [...words.slice(0, clipBy), ellipsis].join(" ");
  }
  return curr.text;
}

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const hasLength = (str) => str.length > 0;
const highlightWords = ({
  text,
  query,
  clipBy,
  matchExactly = false
}) => {
  const safeQuery = typeof query === "string" ? query.trim() : query;
  if (safeQuery === "") {
    return [
      {
        key: uid(),
        text,
        match: false
      }
    ];
  }
  const searchRegexp = buildRegexp({ terms: query, matchExactly });
  return text.split(searchRegexp).filter(hasLength).map((str) => ({
    // Compose the object for a match
    key: uid(),
    text: str,
    match: matchExactly ? str.toLowerCase() === safeQuery.toLowerCase() : searchRegexp.test(str)
  })).map((chunk, index, chunks) => __spreadValues(__spreadValues({}, chunk), typeof clipBy === "number" && {
    // We only overwrite the text if there is a clip
    text: clip(__spreadProps(__spreadValues(__spreadValues({
      curr: chunk
    }, index < chunks.length - 1 && { next: chunks[index + 1] }), index > 0 && { prev: chunks[index - 1] }), {
      // If this wasn't the first chunk, set the previous chunk
      clipBy
    }))
  }));
};

export { highlightWords as default };
