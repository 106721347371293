import React from 'react';
import IMaskMixin from './mixin.js';
import 'prop-types';
import 'imask/esm/imask';

const IMaskInputClass = IMaskMixin(_ref => {
  let {
    inputRef,
    ...props
  } = _ref;
  return React.createElement('input', {
    ...props,
    ref: inputRef
  });
});
const IMaskInputFn = (props, ref) => React.createElement(IMaskInputClass, {
  ...props,
  ref
}) // TODO fix no idea
;

const IMaskInput = React.forwardRef(IMaskInputFn);

export { IMaskInput as default };
